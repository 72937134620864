<template>
  <div class="download">
    <div class="downloadCenter" :style="{background: `url(${activeBj})`}">
      <div class="leftBox">
        <p class="t1">金树丫App</p>
        <p class="t2">极致好用的家校共建共享共育平台</p>
        <!--        <div class="backImg"></div>-->
        <!--        <div class="backImg" style="margin-left: 65px;"></div>-->
        <div class="downloadBox" @mouseover="activeBj = jz">
          <p class="downloadTitle">家长端</p>
          <div class="btnList">
            <div class="btnBox">
              <div class="btn" @click="jumpUrl(jzIos)">
                <img src="../assets/img/ios.png" alt="" />
                iOS下载
              </div>
              <div class="version">
                <span>版本： {{ jzInfo.versionNumber }}</span>
                <span>更新日期： {{
                    jzInfo.createTime ? jzInfo.createTime.slice(0, 10) : ""
                  }}</span>
              </div>
            </div>
            <div class="btnBox">
              <div class="btn" @click="jumpUrl(jzInfo.apkPath)">
                <img src="../assets/img/android.png" alt="" />
                安卓下载
              </div>
              <div class="version">
                <span>版本： {{ jzInfo.versionNumber }}</span>
                <span>更新日期： {{
                    jzInfo.createTime ? jzInfo.createTime.slice(0, 10) : ""
                  }}</span>
              </div>
            </div>
            <div class="qrBox" @mouseover="qrCodeShow = true;qrCode=jzQrCodeUrl" @mouseleave="qrCodeShow = false">
              <img src="../assets/img/down/qr1.png" alt="">
            </div>
          </div>
        </div>
        <div class="downloadBox gardener" @mouseover="activeBj = yd">
          <p class="downloadTitle">园丁端</p>
          <div class="btnList">
            <div class="btnBox">
              <div class="btn" @click="jumpUrl(ydIos)">
                <img src="../assets/img/ios2.png" alt="" />
                iOS下载
              </div>
              <div class="version">
                <span>版本： {{ ydInfo.versionNumber }}</span>
                <span>更新日期： {{
                    ydInfo.createTime ? ydInfo.createTime.slice(0, 10) : ""
                  }}</span>
              </div>
            </div>
            <div class="btnBox">
              <div class="btn" @click="jumpUrl(ydInfo.apkPath)">
                <img src="../assets/img/android2.png" alt="" />
                安卓下载
              </div>
              <div class="version">
                <span>版本： {{ ydInfo.versionNumber }}</span>
                <span>更新日期： {{
                    ydInfo.createTime
                  }}</span>
              </div>
            </div>
            <div class="qrBox" @mouseover="qrCodeYdShow = true;qrCode = ydQrCodeUrl" @mouseleave="qrCodeYdShow = false">
              <img src="../assets/img/down/qr2.png" alt="">
            </div>
          </div>
        </div>
        <transition name="qrCodeBox">
          <div class="qrCodeBox" v-if="qrCodeShow">
            <img :src="qrCode" alt="">
          </div>
        </transition>
        <transition name="qrCodeBox">
          <div class="qrCodeYdBox" v-if="qrCodeYdShow">
            <img :src="qrCode" alt="">
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import qrcode from 'qrcode'

export default {
  data() {
    return {
      androidMsg: {},
      yd: require('@/assets/img/down/yd.png'),
      jz: require('@/assets/img/down/jz.png'),
      iosMsg: {},
      qrCode: '',
      jzQrCodeUrl: '',
      ydQrCodeUrl: '',
      qrCodeShow: false,
      qrCodeYdShow: false,
      activeBj: require('@/assets/img/down/jz.png'),
    ydInfo: {},
      jzInfo: {},
      ydIos: 'itms-apps://itunes.apple.com/app/id1593326584',
      jzIos: 'itms-apps://itunes.apple.com/app/id1560759387',
    };
  },
  mounted() {
    console.log(location.origin);
    this.getVersion();-
    this.createQrcode();
  },
  methods: {
    toAndriod() {
      window.open(this.androidMsg.apkPath);
    },
    async getVersion() {
      // axios
      //   .get("https://api.v2.jinshuy.com/mamserver/api/version/latestVersion")
      //   .then((res) => {
      //     this.androidMsg = res.data.data[0];
      //   });
      axios
          .get("https://api.v2.jinshuy.com/mamserver/api/version/latest/Android?currVersion=0")
          .then((res) => {
           // console.log(res.data.data)
            res.data.data.forEach(item => {
              if(item.appSide.name === 'PARENT'){
                console.log('家长',item)
                this.jzInfo = item
              }
              if(item.appSide.name === 'TEACHER'){
                console.log('园丁',item)
                this.ydInfo = item
              }
            })
            this.androidMsg = res.data.data[0];
          });
    },
    createQrcode() {
      qrcode.toDataURL(`${location.origin}/mdownload?type=jz`,{ errorCorrectionLevel: 'H' }).then(url => {
        this.jzQrCodeUrl = url
      })
      qrcode.toDataURL(`${location.origin}/mdownload?type=yd`,{ errorCorrectionLevel: 'H' }).then(url => {
        this.ydQrCodeUrl = url
      })
    },
    jumpUrl(url){
      location.href = url
    }
  },
};
</script>
<style lang="less">
.qrCodeBox-enter-active,
.qrCodeBox-enter-active-leave-active {
  transition: all 0.1s;
  opacity: 1 !important;
  height: 500px;
}
.qrCodeBox-enter-active-enter,
.qrCodeBox-enter-active-leave-to {
  opacity: 0 !important;
}
.download {
  width: 100%;
  // min-width: 1700px;
  // height: 980px;
  .downloadCenter {
    width: 100%;
    height: 680px;
    //background: url("../assets/img/download.png") no-repeat center center;
    .leftBox {
      width: 1200px;
      margin: auto;
      position: relative;
      p {
        margin: 0;
      }
      .t1 {
        font-family: Microsoft YaHei;
        padding-top: 130px;
        font-size: 50px;
        font-weight: bold;
        color: #1e1e1e;
        letter-spacing: 5px;
        opacity: 1;
      }
      .t2 {
        padding-top: 10px;
        font-size: 20px;
        color: #999999;
        padding-bottom: 40px;
      }
      .backImg {
        margin-bottom: 6px;
        margin-left: 17px;
        width: 140px;
        height: 152px;
        float: left;
        background: url("../assets/img/down.png") no-repeat;
      }
      .downloadBox{
        padding-bottom: 30px;
        .downloadTitle{
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1E1E1E;
        }
        .btnList{
          display: flex;
        }
        >div{
          padding-top: 12px;
        }
        .btnBox{
          margin-right: 16px;
          .btn{
            user-select: none;
            width: 190px;
            height: 58px;
            background: #FBD646;
            opacity: 1;
            border-radius: 6px;
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #1E1E1E;
            font-family: Microsoft YaHei;
            font-weight: bold;
            cursor: pointer;
            &:hover{
              background: #FFE372;
            }
            &:active{
              background: #FBD646;
            }
            img{
              margin-left: 32px;
              margin-right: 16px;
            }
          }
          .version{
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            span{
              padding-top: 4px;
              font-size: 12px;
              color: #B3B3B3;
              font-family: Microsoft YaHei;
              font-weight: 400;
            }
          }
        }
        .qrBox{
          box-sizing: border-box;
          width: 58px;
          height: 58px;
          border: 3px solid #FBD646;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          &:hover{
            border-color: #FFE372;
          }
        }
        &.gardener{
          .btnBox{
            .btn{
              background: #3C90FE;
              color: #FFFFFF;
              &:hover{
                background: #4E9BFF;
              }
              &:active{
                background: #3C90FE;
              }
            }
          }
          .qrBox{
            border-color: #3C90FE;
            &:hover{
              border-color: #4E9BFF;
            }
            &:active{
              border-color: #3C90FE;
            }
          }
        }
      }
      .downBtn {
        clear: both;
        .iosDown {
          cursor: pointer;
          float: left;
          width: 174px;
          height: 68px;
          line-height: 68px;
          background: #fbd646;
          border-radius: 40px;
          font-size: 20px;
          img {
            float: left;
            margin: 20px 20px 0 22px;
          }
        }
      }
      .updateTimeBox {
        clear: both;
        .updateTime {
          padding: 28px 0 0 28px;
          width: 174px;
          float: left;
          color: #6e6e6e;
          font-size: 12px;
        }
      }
      .qrCodeBox{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 180px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
        opacity: 1;
        border-radius: 6px;
        position: absolute;
        left: 486px;
        top: 190px;
        transition: 0.5s;
        img{
          width: 140px;
          height: 140px;
        }
      }
      .qrCodeYdBox{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 180px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
        opacity: 1;
        border-radius: 6px;
        position: absolute;
        left: 486px;
        top: 370px;
        transition: 0.5s;
        img{
          width: 140px;
          height: 140px;
        }
      }
    }
  }
}
</style>
